
import { ref, getCurrentInstance, onMounted } from "vue"
import Layout from "@components/Layout.vue"
import { User } from "@models/User"
import { getUsers, handleActivate, handleDeactivate } from "@requests/Settings"


export default {
    name: "Activation",
    components : { Layout },
    data(){
        return {
            search : ''
        }
    },
    setup(){
        // @ts-ignore
        const { proxy } = getCurrentInstance()
        let users = ref< User[] >( [] );

        const filterStatus = ( value, row ) => {
            return row.active_flag == value
        }

        onMounted( async () => {
            users.value = await getUsers()
        } )

        return { users, handleActivate, handleDeactivate, filterStatus }

    },
}
