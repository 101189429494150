import { get, post } from '@utils/Axios';
import { ElMessage } from "element-plus"

const getUsers = () => {
    return get( '/settings/activation', {} )
        .then( ( res ) => { return res.data } )
}


const handleActivate = ( index, row ) => {
    post( '/settings/activate', { id : row.id, active_flag : true } )
        .then( res => {
            row.active_flag = true;
            ElMessage( { message: res.message, type: 'success', showClose : true } )
        } )
}


const handleDeactivate = ( index, row ) => {
    post( '/settings/deactivate', { id : row.id, active_flag : false } )
        .then( res => {
            row.active_flag = false;
            ElMessage( { message: res.message, type: 'warning', showClose : true } )
        } )
}



export { getUsers, handleActivate, handleDeactivate }
